body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}
.heading {
  position: relative;
  color: black;
}

.heading::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: gray;
  transition: width 0.3s ease;
}

.heading:hover::before {
  width: 100%;
}
@keyframes moveCloud {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.parent-container {
  position: relative;
  width: 100%;
  height: 200px; /* Set height as needed */
}

.cloud {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 50px;
  overflow: hidden;
}

.cloud:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 50px;
  background: orange;
  border-radius: 50%;
  animation: moveCloud 4s linear infinite;
}
